import {isToday} from './utils'
import {$themeConfig} from "@themeConfig";

export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

export const title = (value, replacer = ' ') => {
    if (!value) return ''
    const str = value.toString()

    const arr = str.split(replacer)
    const capitalizedArray = []
    arr.forEach(word => {
        const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
        capitalizedArray.push(capitalized)
    })
    return capitalizedArray.join(' ')
}

export const recordStatusFormat = value => {
    return value === true || value === 1 ? 'Active' : 'Passive'
}

export const isDefaultFormat = value => {
    return value ? 'Yes' : 'No'
}

export const recordStatusVariant = value => {
    return value === true || value === 1 ? 'success' : 'secondary'
}

export const genderOptions = [
    {label: 'None', value: 0},
    {label: 'Male', value: 1},
    {label: 'Female', value: 2},
]

export const statusOptions = [
    {label: 'Passive', value: 0},
    {label: 'Active', value: 1},
]

export const yesNoOptions = [
    {label: 'No', value: false},
    {label: 'Yes', value: true},
]

export const perPageOptions = [10, 25, 50, 100]

export const getApiFile = (filePath, placeHolder = false) => {
    if (filePath !== null && filePath !== '' && filePath !== undefined) {
        return $themeConfig.app.appApiUrl + '/media/img' + filePath;
    } else {
        if (placeHolder) {
            return require('@/assets/images/portrait/placeholder.svg');
        } else {
            return '';
        }
    }
}
export const avatarText = value => {
    if (!value) return ''
    const nameArray = value.split(' ')
    return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
}

export const getPermissionName = value => {
    if (value === 0)
        return 'Listele';
    else if (value === 1)
        return 'Görüntüle';
    else if (value === 2)
        return 'Ekle';
    else if (value === 3)
        return 'Güncelle';
    else if (value === 4)
        return 'Sil';
    else if (value === 5)
        return 'Bekleyen Aksiyon Listesi';
    else if (value === 6)
        return 'Yeni Talep İsteği';
    else
        return 'Tanımsız';
}

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = {year: 'numeric', month: 'short', day: 'numeric'}) => {
    if (!value) return value
    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatDateTime = (value, locale = 'en-US', formatting = {
    year: 'numeric', month: 'short', day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
}) => {
    if (!value) return value
    return new Intl.DateTimeFormat(locale, formatting).format(new Date(value))
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value)
    let formatting = {month: 'short', day: 'numeric'}

    if (toTimeForCurrentDay && isToday(date)) {
        formatting = {hour: 'numeric', minute: 'numeric'}
    }

    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// Strip all the tags from markup and return plain text
export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')
